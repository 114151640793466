import React, { useState } from "react";

const DeleteAccount = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [step, setStep] = useState(1);
  const [isDeleting, setIsDeleting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleNextStep = async () => {
    try {
      if (step === 1 && email.trim() !== "") {
        setLoading(true);
        const response = await fetch(`https://jobss.com.au/api/seekers/email`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email }),
        });
        const data = await response.json();
        setLoading(false);

        if (response.ok) {
          if (data.responseCode === 200) {
            setStep(2);
            setError("Email Found");
          } else {
            setError( "Invalid information provided.");
          }
        } else {
          setError( "Invalid information provided.");
        }
      } else if (step === 2 && password.trim() !== "") {
        await deletingEmail();
      } else {
        setError("Invalid information provided.");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("An error occurred. Please try again.");
      setLoading(false);
    }
  };

  const deletingEmail = async () => {
    try {
      setLoading(true);
      const response = await fetch(`https://jobss.com.au/api/seekerAuth/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password }),
      });
      const data = await response.json();
      setLoading(false);

      if (response.ok) {
        if (data.responseCode === 200) {
          await deleteAccount();
        } else {
          setError("Invalid information provided.");
        }
      } else {
        setError("Invalid information provided.");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("An error occurred. Please try again.");
      setLoading(false);
    }
  };

  const deleteAccount = async () => {
    try {
      setIsDeleting(true);
      const response = await fetch(`https://jobss.com.au/api/seekers/del/email`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      setIsDeleting(false);

      if (response.ok) {
        if (data.responseCode === 200) {
          alert("Your account has been successfully deleted.");
        } else {
          setError(data.message || "Failed to delete account.");
        }
      } else {
        setError(data.message || "Failed to delete account.");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("An error occurred. Please try again.");
      setIsDeleting(false);
    }
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="w-[400px] bg-white p-6 rounded-lg shadow-lg">
        <img
          src="/assets/logo.png"
          alt="Company Logo"
          className="w-[10rem] mx-auto mb-8"
        />
        {error && (
          <div className="mb-4 text-red-500">{error}</div>
        )}
        {step === 1 && (
          <div>
            <input
              type="email"
              placeholder="Enter your email"
              className="w-full border border-gray-300 rounded-lg p-4 mb-4"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              className="w-full bg-red-500 text-white py-4 rounded-lg"
              onClick={handleNextStep}
              disabled={loading}
            >
              {loading ? "Loading..." : "Next"}
            </button>
          </div>
        )}
        {step === 2 && (
          <div>
            <div className="relative mb-4">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                className="w-full border border-gray-300 rounded-lg p-4"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                className="absolute top-1/2 right-3 transform -translate-y-1/2 text-gray-500"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? "Hide" : "Show"}
              </button>
            </div>
            <button
              className="w-full bg-red-500 text-white py-4 rounded-lg"
              onClick={deletingEmail}
              disabled={isDeleting}
            >
              {isDeleting ? "Deleting..." : "Delete My Account"}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default DeleteAccount;
